<template>
  <CContainer>
    <CRow class="justify-content-center">
      <CCol md="6">
        <CCard class="p-4">
          <CCardBody>
            <CForm>
              <h1 class="text-center">
                {{ $t("public.header.login") }}
              </h1>
              <p class="text-center text-muted">
                {{ $t("public.login.msg_account") }}
              </p>
              <CAlert
                show
                color="danger"
                v-for="item in errors.non_field_errors"
                :key="item"
                >{{ item }}</CAlert
              >
              <CInput
                v-model="form.username"
                :placeholder="$t('public.login.placeholder_username')"
                autocomplete="username email"
              >
                <template #prepend-content
                  ><CIcon name="cil-envelope-open"
                /></template>
              </CInput>
              <CInput
                v-model="form.password"
                :placeholder="$t('public.login.placeholder_password')"
                type="password"
                autocomplete="curent-password"
              >
                <template #prepend-content
                  ><CIcon name="cil-lock-locked"
                /></template>
              </CInput>
              <CRow class="mb-5">
                <CCol col="6" class="text-left">
                  <vue-ladda
                    :loading="loading"
                    data-style="zoom-in"
                    button-class="btn btn-primary px-4"
                    @click.prevent="login"
                    >{{ $t("public.login.button_label") }}</vue-ladda
                  >
                </CCol>
                <CCol col="6" class="text-right">
                  <CButton
                    color="link"
                    class="px-0"
                    @click.prevent="passwordReset"
                    >{{ $t("public.login.lost_password") }}</CButton
                  >
                  <CButton
                    color="link"
                    class="d-lg-none"
                    href="/accounts/signup"
                    >{{ $t("public.login.register") }}
                  </CButton>
                </CCol>
              </CRow>
              <CRow class="mb-5">
                <CCol col="6" class="text-right">
                  <CButton
                    color="primary"
                    :block="true"
                    class="px-0"
                    @click.prevent="oauth2('facebook')"
                    >Facebook</CButton
                  >
                </CCol>
                <CCol col="6" class="text-right">
                  <CButton
                    color="primary"
                    :block="true"
                    class="px-0"
                    @click.prevent="oauth2('google')"
                    >Google</CButton
                  >
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      remember: true,
      staySignedIn: true,
      fetchUser: true,
      errors: {},
      loading: false,
    };
  },

  methods: {
    setErrors(res) {
      this.errors = res.data;
    },

    passwordReset() {
      this.$router.push({ name: "PasswordReset" });
    },

    login() {
      this.loading = true;
      this.$store
        .dispatch("auth/login", {
          body: this.form, // VueResource
          data: this.form, // Axios
          remember: this.remember,
          fetchUser: this.fetchUser,
          staySignedIn: this.staySignedIn,
        })
        .then(null, (res) => {
          this.setErrors(res.response);
          this.loading = false;
        });
    },

    oauth2(type) {
      this.$auth.oauth2(type, {
        staySignedIn: this.staySignedIn,
      });
    },
  },
};
</script>
